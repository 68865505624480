<template>
    <div class="trial">
        <el-form
            ref="form"
            style="flex:1;margin-top:20px;margin-left: 40px;padding-bottom: 50px;"
            label-position="left"
            :model="form"
            label-width="100px"
        >
            <el-form-item label="报备品牌">
                <span class="text">腾讯企业邮箱</span>
            </el-form-item>
            <el-form-item label="公司名称">
                <span class="text">{{ form.companyName }}</span>
                <span
                    @click="copyText(form.companyName)"
                    style="margin-left:20px;color:#2370EB;cursor: pointer;"
                    >复制</span
                >
            </el-form-item>
            <el-form-item label="邮箱版本">
                <span class="text">{{
                    form.emailVersionType == 1
                        ? '全新用户'
                        : form.emailVersionType == 2
                        ? '已有新版基础版'
                        : form.emailVersionType == 3
                        ? '已有旧版基础版'
                        : '- -'
                }}</span>
            </el-form-item>
            <el-form-item
                v-show="form.emailVersionType != ''"
                :label="
                    form.emailVersionType == 1
                        ? '手机号'
                        : form.emailVersionType == 2
                        ? '企业ID'
                        : form.emailVersionType == 3
                        ? '管理员账号'
                        : ''
                "
            >
                <span class="text">{{ form.emailVersionTypeName }}</span>
            </el-form-item>
            <el-form-item label="用户数">
                <span class="text">{{ form.number }}</span>
            </el-form-item>
            <el-form-item label="试用天数">
                <span class="text">{{ form.term }}</span>
            </el-form-item>
            <el-form-item label="备注">
                <span class="text">{{ form.remark }}</span>
            </el-form-item>
            <el-form-item label="审核结果">
                <span class="text">
                    <el-radio v-model="auditStatus" label="1"
                        >试用通过</el-radio
                    >
                    <el-radio v-model="auditStatus" label="2"
                        >试用驳回</el-radio
                    >
                </span>
            </el-form-item>
            <el-form-item
                label="到期时间"
                required
                style="margin-left:-10px"
                v-if="auditStatus == 1"
            >
                <el-date-picker
                    v-model="tencentTrialExpireTime"
                    size="small"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width:270px;margin-left:10px"
                    type="date"
                    placeholder="选择日期"
                >
                </el-date-picker>
            </el-form-item>
            <el-form-item v-if="auditStatus == 1">
                <el-radio-group v-model="expireTime" size="small">
                    <el-radio-button
                        label="30天"
                        @click.native.prevent="clickTime('30天')"
                    ></el-radio-button>
                    <el-radio-button
                        label="60天"
                        @click.native.prevent="clickTime('60天')"
                    ></el-radio-button>
                    <el-radio-button
                        label="90天"
                        @click.native.prevent="clickTime('90天')"
                    ></el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item
                label="账号信息"
                required
                style="margin-left:-10px"
                v-if="auditStatus == 1"
            >
                <el-input
                    type="textarea"
                    :rows="2"
                    style="width:270px;margin-left:10px"
                    placeholder="请输入内容"
                    v-model="accountInfo"
                >
                </el-input>
            </el-form-item>
            <el-form-item
                label="驳回原因"
                required
                style="margin-left:-10px"
                v-if="auditStatus == 2"
            >
                <el-input
                    type="textarea"
                    :rows="2"
                    style="width:270px;margin-left:10px"
                    placeholder="请输入内容"
                    v-model="rejectReason"
                >
                </el-input>
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background:none;border-color:#D5D6DF;color:#666666;margin-left:270px"
                @click="onClose"
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                style="background:#2370EB;border-color:#2370EB;color:#fff;"
                size="mini"
                @click="onSubmit"
                >确认</el-button
            >
        </div>
    </div>
</template>
<script>
import { tmTrialUpdate } from '@/api/user/business/customer';
import { trialList } from '@/api/user/ae/tencent';
export default {
    data() {
        return {
            form: {},
            auditStatus: '1',
            tencentTrialExpireTime: '',
            rejectReason: '',
            accountInfo: '',
            id: '',
            expireTime: '30天',
        };
    },
    methods: {
        echoData(data) {
            // console.log(data);
            this.auditStatus = '1';
            this.tencentTrialExpireTime = '';
            this.accountInfo = '';
            this.rejectReason = '';
            this.form = data;
            this.id = data.customerId;
            let datas = {
                param: {
                    customerId: data.customerId,
                },
            };
            trialList(datas).then((res) => {
                // console.log(res);
                this.form = res.data.list[res.data.list.length - 1];
                let date = new Date();
                this.tencentTrialExpireTime =
                    this.$searchTime.getNextDate(date, -30) + ' 00:00:00';
            });
        },
        onSubmit() {
            let data = {
                param: {
                    customerId: this.id,
                    customerTencentTrialDO: { auditStatus: this.auditStatus },
                },
            };
            if (this.auditStatus == 1) {
                if (!this.tencentTrialExpireTime) {
                    return this.$message.error('请选择到期时间');
                } else if (!this.accountInfo) {
                    return this.$message.error('请输入账号信息');
                } else {
                    data.param.customerTencentTrialDO.tencentTrialExpireTime = this.tencentTrialExpireTime;
                    data.param.customerTencentTrialDO.accountInfo = this.accountInfo;
                }
            } else if (this.auditStatus == 2) {
                if (!this.rejectReason) {
                    return this.$message.error('请输入驳回原因');
                } else {
                    data.param.customerTencentTrialDO.rejectReason = this.rejectReason;
                }
            }
            tmTrialUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('审核成功');
                }
                this.onClose();
            });
        },
        // 到期时间点击
        clickTime(e) {
            // console.log(e);
            e === this.expireTime
                ? (this.expireTime = '')
                : (this.expireTime = e);
            let date = new Date();
            if (e == this.expireTime && e == '30天') {
                this.tencentTrialExpireTime =
                    this.$searchTime.getNextDate(date, -30) + ' 00:00:00';
            } else if (e == this.expireTime && e == '60天') {
                this.tencentTrialExpireTime =
                    this.$searchTime.getNextDate(date, -60) + ' 00:00:00';
            } else if (e == this.expireTime && e == '90天') {
                this.tencentTrialExpireTime =
                    this.$searchTime.getNextDate(date, -90) + ' 00:00:00';
            } else {
                this.tencentTrialExpireTime = '';
            }
        },
        onClose() {
            this.$emit('close');
            this.tencentTrialExpireTime = '';
            this.accountInfo = '';
            this.rejectReason = '';
        }, //复制
        copyText(data) {
            let url = data;
            let oInput = document.createElement('input');
            oInput.value = url;
            document.body.appendChild(oInput);
            oInput.select(); // 选择对象;
            document.execCommand('Copy'); // 执行浏览器复制命令
            this.$message({
                message: '复制成功',
                type: 'success',
            });
            oInput.remove();
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.trial {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .brand {
        display: inline-block;
        width: 260px;
        padding-left: 10px;
        background: #fafafa;
        margin-left: 10px;
        color: #333333;
        font-size: 14px;
        border-radius: 2px;
    }
    .el-input {
        width: 270px;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        z-index: 1000;
        bottom: 0;
        position: fixed;
        button {
            width: 90px;
            z-index: 1000;
        }
    }
}
</style>

<template>
  <div class="extend">
    <div class="form-box">
      <el-form
        style="flex:1;margin-top:20px;margin-left: 40px;"
        label-position="left"
        label-width="100px"
      >
        <el-form-item>
          <span class="text">
            放弃报备后，报备将会立即到期<br />
            客户放弃报备时若有试用中的信息则会一同中止
          </span>
        </el-form-item>
      </el-form>
    </div>
    <div class="but">
      <el-button
        round
        type="primary"
        size="mini"
        style="background:none;border-color:#D5D6DF;color:#666666;margin-left: auto;"
        @click="onClose"
        >取消</el-button
      >
      <el-button
        round
        type="primary"
        style="background:#2370EB;border-color:#2370EB;color:#fff;margin-right:30px"
        size="mini"
        @click="onSubmit"
        >确定</el-button
      >
    </div>
  </div>
</template>
<script>
import { tmReportGive } from "@/api/user/business/customer";
export default {
  data() {
    return {
      data: "",
    };
  },
  methods: {
    getData(row) {
      this.data = row;
    },
    onSubmit() {
      let data = {
        param: {
          customerId: this.data.customerId,
          customerTencentReportDO: {
            id: this.data.id,
          },
        },
      };

      tmReportGive(data).then((res) => {
        if (res.code == 200) {
          this.$message.success("放弃成功");
        }
        this.onClose();
      });
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
  /* WebKit browsers 适配谷歌 */
  font-size: 12px !important;
}
.extend {
  width: 100%;

  .form-box {
    height: 260px;
  }
  .but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 72px;
    }
  }
}
</style>

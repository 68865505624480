import http from '@/utils/http.js'

// 腾讯报备添加
export function reportAdd(data) {
    return http({
        url: "/jasoboss/customer/tencent/report/add",
        method: "post",
        data
    })
}
// 阿里报备添加
export function aliReportAdd(data) {
    return http({
        url: "/jasoboss/customer/ali/report/add",
        method: "post",
        data
    })
}
// 腾讯是试用添加
export function trialAdd(data) {
    return http({
        url: "/jasoboss/customer/tencent/trial/add",
        method: "post",
        data
    })
}
// 腾讯报备列表
export function reportList(data) {
    return http({
        url: "/jasoboss/customer/tencent/report/list",
        method: "post",
        data
    })
}
// 腾讯试用列表
export function trialList(data) {
    return http({
        url: "/jasoboss/customer/tencent/trial/list",
        method: "post",
        data
    })
}
// 腾讯报备历史列表
export function reportHistoryList(data) {
    return http({
        url: "/jasoboss/customer/tencent/report/history/list",
        method: "post",
        data
    })
}
// 腾讯试用历史列表
export function trialHistoryList(data) {
    return http({
        url: "/jasoboss/customer/tencent/trial/history/list",
        method: "post",
        data
    })
}
